import React from "react";

export const Icons = () => (
  <svg className="dn">
    <defs>
      <symbol id="logo" viewBox="0 0 112 148">
        <path
          d="M39.992,148c-0.747,0-1.5-0.02-2.262-0.059c-5.701-0.294-11.534-2.044-16.867-5.06
          c-5.187-2.934-9.798-6.998-13.335-11.752c-6.59-8.86-8.964-19.203-6.683-29.126c2.315-10.073,10.17-17.453,20.5-19.259
          c9.827-1.719,19.214,2.079,24.85,9.951c2.733-1.489,4.803-1.712,5.796-1.712c0.002,0,0.004,0,0.006,0
          c1.343,0.001,2.431,1.105,2.429,2.465c-0.001,1.359-1.09,2.461-2.431,2.461c-0.002,0-0.003,0-0.005,0
          c-1.025,0-2.231,0.413-3.48,1.105c1.809,4.48,3.824,12.099,0.924,16.261c-1.15,1.65-3.473,3.393-8.012,2.323
          c-4.557-1.074-6.994-4.21-6.519-8.387c0.38-3.343,2.726-7.366,6.276-10.759c0.347-0.332,0.69-0.644,1.027-0.937
          c-4.546-6.283-12.103-9.301-20.032-7.916c-8.37,1.464-14.727,7.411-16.591,15.522c-1.948,8.477,0.123,17.372,5.831,25.046
          c6.435,8.652,16.615,14.345,26.565,14.858c10.391,0.536,18.845-2.906,25.856-10.521c5.657-6.144,10.245-14.871,14.025-26.681
          c4.132-12.909,5.124-26.297,6.084-39.245c0.789-10.653,1.606-21.669,4.143-32.521c0.045-0.191,0.09-0.382,0.136-0.574
          c-3.684,1.234-7.473,1.743-11.174,2.24c-5.68,0.763-11.045,1.484-15.485,4.742c-6.773,4.971-9.557,13.255-8.337,18.392
          c0.937,3.945,4.28,4.982,6.859,4.671c2.825-0.34,5.673-2.382,5.671-6.176c-0.001-1.36,1.087-2.463,2.43-2.464c0,0,0.001,0,0.002,0
          c1.342,0,2.431,1.101,2.432,2.461c0.005,6.781-5.159,10.492-9.959,11.07c-4.758,0.573-10.594-1.813-12.162-8.409
          c-2.107-8.87,3.688-18.745,10.211-23.533c5.435-3.989,11.67-4.827,17.699-5.637c4.794-0.644,9.339-1.254,13.444-3.347
          c2.649-8.681,6.443-16.95,11.319-24.66c1.63-2.579,4.806-3.538,7.55-2.281c2.633,1.207,3.848,3.965,3.024,6.864
          c-2.766,9.742-9.226,18.22-17.798,23.406c-0.41,1.445-0.786,2.902-1.129,4.369c-2.449,10.474-3.251,21.293-4.026,31.755
          c-0.981,13.237-1.995,26.925-6.307,40.396c-4.005,12.509-8.942,21.839-15.095,28.521C59.967,143.922,50.758,148,39.992,148z
           M44.486,100.061c-2.477,2.389-4.487,5.372-4.753,7.712c-0.092,0.806-0.262,2.306,2.79,3.026c1.528,0.36,2.516,0.237,2.936-0.365
          C46.676,108.686,45.767,103.753,44.486,100.061z M106.212,4.935c-0.296,0-0.665,0.109-0.945,0.552
          c-3.466,5.481-6.358,11.26-8.65,17.276c4.936-4.425,8.62-10.251,10.455-16.711c0.152-0.536,0.049-0.829-0.355-1.015
          C106.698,5.029,106.487,4.935,106.212,4.935z"
        />
      </symbol>
    </defs>
  </svg>
);
